<template>
    <div class="windows-dialog">
        <el-dialog :close-on-click-modal="false" :title="isEdit ? '编辑人员信息' : '新建人员信息'" width="500px" :visible.sync="show" class="selfInputBox">
            <div class="headerBtnBox buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <el-button @click="close">取消</el-button>
            </div>

            <div class="dialog-content">
                <!-- <el-tabs tab-position="left">
                    <el-tab-pane label="基本信息" disabled @click="move()">
                        <div class="dialog-box">
                            基本信息
                            <a href="https://www.baidu.com/">https://www.baidu.com/</a>
                        </div>
                        <div class="dialog-box">
                            信息设置
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="信息设置" disabled @click="move()">
                        <div class="dialog-box">
                            
                        </div>
                    </el-tab-pane>
                </el-tabs> -->

                <div class="dialog-title">
                    <a>基本信息</a>
                    <a>信息设置</a>
                </div>

                <div class="dialog-box">
                    <!-- 基本信息 -->
                    <!-- <a href="https://www.baidu.com/">https://www.baidu.com/</a> -->
                    <el-form ref="form">
                        <el-form-item label="头像" prop="" style="width: 100%">
                            <!-- <el-upload drag class="SelfUploadBox" action :auto-upload="false" list-type="picture-card"
                                :on-preview="handlePreview" :on-remove="handlePreview" :before-remove="beforeRemove"
                                :on-change="uploadSectionFile" :limit="1" ref="businessLicense">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖拽到此处或点击上传</div>
                            </el-upload> -->

                            <el-upload drag class="SelfUploadBox" action="/api/admin/uploadImage" :headers="Headers"
                                name="image" list-type="picture-card" :limit="1" :on-success="handleAvatarSuccess"
                                :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :show-file-list="true"
                                :file-list="photoList">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖拽到此处或点击上传</div>
                            </el-upload>
                        </el-form-item>

                        <el-form-item label="真实姓名" prop="">
                            <el-input v-model="userInfo.realname" />
                        </el-form-item>

                        <el-form-item label="昵称" prop="">
                            <el-input v-model="userInfo.nickname" />
                        </el-form-item>

                        <el-form-item label="手机号码" prop="">
                            <el-input v-model="userInfo.mobile" />
                        </el-form-item>

                        <el-form-item label="性别" prop="" style="clear: both">
                            <!-- 1男 2女 3未知 -->
                            <el-radio v-model="userInfo.gender" :label="1">男</el-radio>
                            <el-radio v-model="userInfo.gender" :label="2">女</el-radio>
                            <el-radio v-model="userInfo.gender" :label="3">未知</el-radio>
                        </el-form-item>

                        <el-form-item label="邮箱地址" prop="">
                            <el-input v-model="userInfo.email" />
                        </el-form-item>

                        <el-form-item label="身份证号" prop="">
                            <el-input v-model="userInfo.identity" />
                        </el-form-item>

                        <el-form-item label="入职时间" prop="" style="clear: both">
                            <!-- <el-input placeholder="请选择日期" suffix-icon="el-icon-date" v-model="userInfo.entry_date" /> -->
                            <el-date-picker v-model="userInfo.entry_date" type="date" format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="科室">
                            <el-select v-model="userInfo.level_id" placeholder="请选择科室">
                                <el-option :label="item.name" :value="item.id" v-for="item in levelList"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="岗位">
                            <el-select v-model="userInfo.position_id" placeholder="请选择岗位">
                                <el-option :label="item.name" :value="item.id" v-for="item in positionList"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="所属医院">
                            <el-select v-model="userInfo.dept_id" placeholder="请选择所属医院">
                                <el-option :label="item.name" :value="item.id" v-for="item in depList"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="所属卫健委">
                            <el-select v-model="userInfo.h_id" placeholder="请选择卫健委">
                                <el-option :label="item.name" :value="item.id" v-for="item in healthList"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="所属城市" style="width: 100%">
                            <el-select v-model="userInfo.province_id" placeholder="省">
                                <el-option :label="item.name" :value="item.id" v-for="item in province"
                                    :key="item.id"></el-option>
                            </el-select>
                            <el-select v-show="city.length > 0" v-model="userInfo.city_id" placeholder="市">
                                <el-option :label="item.name" :value="item.id" v-for="item in city"
                                    :key="item.id"></el-option>
                            </el-select>
                            <el-select v-show="district.length > 0" v-model="userInfo.district_id" placeholder="县">
                                <el-option :label="item.name" :value="item.id" v-for="item in district"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="角色" class="roleListBox" style="width: 100%">
                            <el-checkbox @change="selectRoleList($event, item.id)" :label="item.id"
                                v-for="item in roleList" :key="item.id"
                                :checked="userInfo.role_ids.split(',').includes(String(item.id))">{{ item.name }}</el-checkbox>
                        </el-form-item>

                        <el-form-item label="地址" style="width: 100%">
                            <el-input v-model="userInfo.address" />
                        </el-form-item>

                        <el-form-item label="备注" style="width: 100%">
                            <el-input v-model="userInfo.note" />
                        </el-form-item>

                        <el-form-item label="个人简介" style="width: 100%">
                            <el-input v-model="userInfo.intro" />
                        </el-form-item>

                        <el-form-item label="管理员" prop="" style="clear: both">
                            <el-switch v-model="userInfo.is_admin" :active-value="1" active-color="#5BD995"
                                active-text="是" :inactive-value="2" inactive-color="#D9D9D9" inactive-text="否"
                                class="switchSelfBox">
                            </el-switch>
                        </el-form-item>

                        <el-form-item label="状态" prop="" style="clear: both">
                            <el-switch v-model="userInfo.status" :active-value="1" active-color="#5BD995"
                                active-text="正常" :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用"
                                class="switchSelfBox">
                            </el-switch>
                        </el-form-item>
                    </el-form>

                    <h3 class="minddle-title" style="">用户信息设置</h3>

                    <div class="dialog-cell">
                        <el-form ref="form1">
                            <el-form-item label="app权限角色" class="longcell">
                                <!-- APP权限角色分配：1收集人 2入库人 3出库人 -->
                                <!-- <el-checkbox @change="selectChildrenCheck($event,1)"
                                    :checked="userInfo.type.split(',').includes('1')" :label="1">收集人</el-checkbox>
                                <el-checkbox @change="selectChildrenCheck($event,2)"
                                    :checked="userInfo.type.split(',').includes('2')" :label="2">入库人</el-checkbox>
                                <el-checkbox @change="selectChildrenCheck($event,3)"
                                    :checked="userInfo.type.split(',').includes('3')" :label="3">出库人</el-checkbox> -->

                                <el-checkbox @change="selectChildrenCheck($event, item.id)" :label="item.id"
                                    v-for="item in power" :key="item.id"
                                    :checked="userInfo.type.split(',').includes(String(item.id))">{{ item.name }}</el-checkbox>
                            </el-form-item>

                            <el-form-item label="用户名" prop="">
                                <el-input v-model="userInfo.username" />
                            </el-form-item>

                            <el-form-item label="登录密码" prop="">
                                <el-input show-password placeholder="请输入密码" v-model="userInfo.password" />
                            </el-form-item>

                            <el-form-item label="封箱签名数量" prop="">
                                <el-input v-model="userInfo.sealing_number" />
                            </el-form-item>

                            <el-form-item label="入库签名数量" prop="">
                                <el-input v-model="userInfo.ware_itemcate" />
                            </el-form-item>

                            <el-form-item label="入库是否核验" prop="" style="clear: both">
                                <el-switch v-model="userInfo.is_verify_ware" :active-value="1" active-color="#5BD995"
                                    active-text="是" :inactive-value="0" inactive-color="#D9D9D9" inactive-text="否"
                                    class="switchSelfBox">
                                </el-switch>
                            </el-form-item>

                            <el-form-item label="出库是否核验" prop="" style="clear: both">
                                <el-switch v-model="userInfo.is_verify_out" :active-value="1" active-color="#5BD995"
                                    active-text="是" :inactive-value="0" inactive-color="#D9D9D9" inactive-text="否"
                                    class="switchSelfBox">
                                </el-switch>
                            </el-form-item>

                            <!-- 
                            <el-form-item label="功能模块" class="longcell">
                                <el-checkbox-group v-model="userInfo.type">
                                    <el-checkbox label="PDA" name="type"></el-checkbox>
                                    <el-checkbox label="微信小程序" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>

                            <el-form-item label="后台所属角色" class="longcell">
                                <el-checkbox-group v-model="userInfo.type">
                                    <el-checkbox label="管理员" name="type"></el-checkbox>
                                    <el-checkbox label="双街镇社区卫生服务中心" name="type"></el-checkbox>
                                    <el-checkbox label="和平区南营门街社区卫生服务中心" name="type"></el-checkbox>
                                    <el-checkbox label="天津和平区卫健委" name="type"></el-checkbox>
                                    <el-checkbox label="天津北辰区卫健委" name="type"></el-checkbox>
                                    <el-checkbox label="河东区大王庄街社区卫生服务中心" name="type"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item> -->
                        </el-form>

                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>

            <!-- <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="close">保存</el-button>
                <el-button @click="$refs.form.resetFields()">重置</el-button>
            </div>
        </span> -->
        </el-dialog>
    </div>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'

    export default {
        data() {
            return {
                show: false,

                state: true,

                // 回显图片的路径
                photoList: [{
                    url: ''
                }],
                // 图片的路径
                dialogImageUrl: '',

                userInfo: {
                    realname: '',
                    username: '',
                    password: '',
                    dept_id: '',
                    role_ids: '',
                    level_id: '',
                    position_id: '',
                    mobile: '',
                    province_id: '',
                    city_id: '',
                    district_id: '',
                    nickname: '',
                    avatar: '',
                    email: '',
                    identity: '',
                    gender: '',
                    entry_date: '',
                    city_name: '',
                    address: '',
                    is_admin: '',
                    intro: '',
                    note: '',
                    status: 1,
                    sort: '',
                    mark: '1',
                    type: '',
                    sealing_number: '',
                    ware_itemcate: '',
                    h_id: '',
                    is_verify_ware: '',
                    is_verify_out: '',
                    salt: ''
                },

                levelList: [],
                positionList: [],
                depList: [],
                healthList: [],
                roleList: [],

                province: [],
                city: [],
                district: [],

                isEdit: false,

                power: [{
                        id: 1,
                        name: '收集人'
                    },
                    {
                        id: 2,
                        name: '入库人'
                    },
                    {
                        id: 3,
                        name: '出库人'
                    }
                ]
            }
        },

        computed: {
            ...mapState('m_item', [
                'LevelList',
                'PositionList',
                'DepList',
                'HealthList',
                'Province',
                'City',
                'District',
                'RoleList'
            ]),

            Headers() {
                let token = localStorage.getItem('token')
                return {
                    token
                }
            }
        },

        created() {},

        watch: {
            show(value) {
                if (this.show) {

                    if (this.levelList.length == 0) {

                        this.getlevel()
                        setTimeout(() => {
                            this.levelList = this.LevelList
                        }, 1000)

                    }

                    if (this.positionList.length == 0) {
                        this.getposition()
                        setTimeout(() => {
                            this.positionList = this.PositionList
                        }, 1000)
                    }

                    if (this.depList.length == 0) {
                        this.getdep()
                        setTimeout(() => {
                            this.depList = this.DepList
                        }, 1000)
                    }

                    if (this.healthList.length == 0) {
                        this.gethealth()
                        setTimeout(() => {
                            this.healthList = this.HealthList
                        }, 1000)
                    }

                    if (this.roleList.length == 0) {
                        this.getrole()
                        setTimeout(() => {
                            this.roleList = this.RoleList
                        }, 1200)
                    }

                    if (this.province.length == 0) {
                        this.getProvinceCityDistrict()

                        // province: [], // city: [], // district: [],
                        setTimeout(() => {
                            this.province = this.Province
                        }, 2000)
                    }



                }
            },

            dialogImageUrl(value) {
                this.userInfo.avatar = this.dialogImageUrl
            },

            'userInfo.province_id': {
                deep: true,
                handler: function(newVal, oldVal) {
                    this.userInfo.city_id = ''
                    this.userInfo.district_id = ''
                    this.city = []
                    this.district = []
                    if (!newVal) {
                        return
                    }
                    this.getProvinceCityDistrict(newVal, 2)
                    // province: [], // city: [], // district: [],
                    setTimeout(() => {
                        this.city = this.City
                    }, 1000)
                }
            },

            'userInfo.city_id': {
                deep: true,
                handler: function(newVal, oldVal) {
                    this.userInfo.district_id = ''
                    if (!newVal) {
                        return
                    }
                    this.getProvinceCityDistrict(newVal, 3)
                    // province: [], // city: [], // district: [],
                    setTimeout(() => {
                        this.district = this.District
                    }, 1000)
                }
            },

            'userInfo.district_id': {
                deep: true,
                handler: function(newVal, oldVal) {}
            }

            // "userInfo.type": {
            //     deep: true,
            //     handler: function(newVal, oldVal) {
            //         console.log("！！！！！！！！！！！！！！", newVal);
            //     }
            // },
        },

        methods: {

            selectRoleList(val, value) {
                var a = this.userInfo.role_ids ? this.userInfo.role_ids.split(',') : []
                if (val) {
                    a.push(String(value))
                } else {
                    a = a.filter((item) => item != String(value))
                }
                this.userInfo.role_ids = a.toString()
                console.log(this.userInfo.role_ids)
            },

            selectChildrenCheck(val, value) {
                var a = this.userInfo.type ? this.userInfo.type.split(',') : []
                if (val) {
                    a.push(String(value))
                } else {
                    a = a.filter((item) => item != String(value))
                }
                this.userInfo.type = a.toString()
                console.log(this.userInfo.type)
            },

            open() {
                this.isEdit = false

                // 回显图片的路径
                this.photoList = []
                // 图片的路径
                this.dialogImageUrl = ''

                this.roleList = []

                this.power = []

                setTimeout(() => {
                    this.power = [{
                            id: 1,
                            name: '收集人'
                        },
                        {
                            id: 2,
                            name: '入库人'
                        },
                        {
                            id: 3,
                            name: '出库人'
                        }
                    ]
                }, 1000)

                this.$nextTick(() => {
                    this.userInfo = {
                        realname: '',
                        username: '',
                        password: '',
                        dept_id: '',
                        role_ids: '',
                        level_id: '',
                        position_id: '',
                        mobile: '',
                        province_id: '',
                        city_id: '',
                        district_id: '',
                        nickname: '',
                        avatar: '',
                        email: '',
                        identity: '',
                        gender: '',
                        entry_date: '',
                        city_name: '',
                        address: '',
                        is_admin: '',
                        intro: '',
                        note: '',
                        status: 1,
                        sort: '',
                        mark: '1',
                        type: '',
                        sealing_number: '',
                        ware_itemcate: '',
                        h_id: '',
                        is_verify_ware: '',
                        is_verify_out: '',
                        salt: ''
                    }
                })

                // this.formShow = true
                this.show = true

                // this.getAdminList(24)

                // this.$forceUpdate()
            },

            edit(val) {
                this.isEdit = true

                this.$http
                    .get('/api/admin/' + val.id)
                    .then((res) => {
                        if (res.code == 200) {
                            this.setInfo(res.data)
                        }

                        if (res.code != 200) {
                            //请求错误
                            this.$message.error(res.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },

            setInfo(info) {
                console.log('***************************************', info)

                this.userInfo = {
                    ...info
                }

                this.dialogImageUrl = info.avatar
                this.photoList = [{
                    url: this.GLOBAL.BASE_URL + info.avatar
                }]

                this.userInfo.entry_date = this.timestampToTime(info.entry_date, 1)

                var b = 1
                var a = setInterval(() => {
                    this.userInfo.province_id = info.province_id
                    this.userInfo.city_id = info.city_id
                    this.userInfo.district_id = info.district_id
                    b++
                    if (b > 10) {
                        clearInterval(a)
                    }
                }, 500)

                this.show = true
            },
            close() {
                this.show = false

                this.isEdit = false

                // this.$emit('refresh')
                // setTimeout(() => {
                //     this.formShow = false
                // }, 1000)

                this.$parent.getAdminList()
            },

            save() {
                this.userInfo.entry_date = this.TimeToTimestamp(this.userInfo.entry_date + ' 00:00:00')

                if (this.isEdit) {

                    this.$http
                        .put('/api/admin/' + this.userInfo.id, this.userInfo)
                        .then((res) => {
                            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

                            if (res.code != 200) {
                                //请求错误
                                this.$message.error(res.msg)
                            }

                            if (res.code == 200) {
                                this.close()
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                } else {

                    this.$http
                        .post('/api/admin', this.userInfo)
                        .then((res) => {
                            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

                            if (res.code != 200) {
                                //请求错误
                                this.$message.error(res.msg)
                            }

                            if (res.code == 200) {
                                this.close()
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                }
            },

            // uploadSectionFile(file) {

            //     // 图片的路径
            //     this.dialogImageUrl = file.url;
            //     console.log("111111" + file.url);
            // },

            // beforeRemove(file) {

            //     // 图片的路径
            //     this.dialogImageUrl = file.url;
            //     console.log("22222" + file.url);
            // },

            // handlePreview(file) {

            //     // 图片的路径
            //     this.dialogImageUrl = file.url;
            //     console.log("33333" + file.url);
            // },

            // move() {

            //     console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~");

            // },

            handleRemove(file, fileList) {
                console.log(file, fileList)
                this.dialogImageUrl = ''
            },
            handleAvatarSuccess(res, file) {
                this.dialogImageUrl = res.data
                console.log(this.dialogImageUrl)
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!')
                }
                return /* isJPG && */ isLt2M
            }
        }
    }
</script>


<style scoped>
    /deep/.el-tabs__header {
        background-color: #fff;
    }

    /deep/.el-tabs__item.is-disabled {
        color: #768dfc;
        cursor: default;
    }

    .roleListBox {
        /* border: 1px red solid;
        box-sizing: border-box; */
    }

    /deep/.roleListBox .el-form-item__content {
        /*   border: 1px green solid;
        box-sizing: border-box; */

        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
    }

    /deep/.roleListBox .el-checkbox {
        /*   border: 1px red solid;
        box-sizing: border-box; */
        /* display: inline-block; */
    }

    /deep/.el-input__prefix {
        /* border: 1px red solid;
        box-sizing: border-box; */
        display: none;
    }
</style>